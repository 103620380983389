import ko from 'knockout';
import { ListField } from '../../model';

ko.bindingHandlers['addComment'] = {
	init(element, valueAccessor, allBindingsAccessor, viewModel: ListField, bindingContext) {
		let value = valueAccessor();

		import(/* webpackChunkName: "add-comment" */ "../../comment/AddComment").then(m => {
			const AddComment = m.default;

			let addComment = new AddComment(viewModel, value);

			let childBindingContext = bindingContext.createChildContext(
				addComment,
				null,
				context => { ko.utils.extend(context, valueAccessor()); }
			);

			ko.applyBindingsToDescendants(childBindingContext, element);
		});

		return { controlsDescendantBindings: true };
	}
}
