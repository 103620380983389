import ko from 'knockout';
import { ListItemForm } from '../../model';

ko.bindingHandlers['commentForm'] = {
	init(element, valueAccessor, allBindingsAccessor, viewModel: ListItemForm, bindingContext) {
		let value = valueAccessor();

		import(/* webpackChunkName: "comment-form" */ "../../comment/CommentForm").then(m => {
			const CommentForm = m.default;

			let addComment = new CommentForm(viewModel, value);

			let childBindingContext = bindingContext.createChildContext(
				addComment,
				null,
				context => { ko.utils.extend(context, valueAccessor()); }
			);

			ko.applyBindingsToDescendants(childBindingContext, element);
		});

		return { controlsDescendantBindings: true };
	}
}
