
import ko from 'knockout';
import _ from 'lodash';
import { Schema } from "prosemirror-model";
import { schema as basicSchema } from "prosemirror-schema-basic";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { exampleSetup } from "prosemirror-example-setup";
import { addMentionNodes, getMentionsPlugin } from 'prosemirror-mentions';
import { ScalarField } from '../../model';
import appUrl from '../../../../util/appUrl';
import './proseMirror.less';
import './proseMirrorMention.less';

type MentionType = 'mention' | 'tag';

interface MentionItem {
	id: number;
	name: string;
	email: string;
}

ko.bindingHandlers['commentEditor'] = {
	init(element: HTMLInputElement, valueAccessor, allBindingsAccessor, viewModel: ScalarField) {
		const nodes = basicSchema.spec.nodes.remove('image')
		const schema = new Schema({
			nodes: addMentionNodes(nodes),
			marks: basicSchema.spec.marks
		});

		const plugins = exampleSetup({schema: schema});

		const mentionPlugin = getMentionsPlugin({
			getSuggestions: (type: MentionType, text: string, done: (items: MentionItem[]) => void) => {
				setTimeout(() => {
					if (type === 'mention') {
						$.ajax({
							url: appUrl('~/comment/users/'),
							data: { text: text },
							method: 'post',
							dataType: 'json',
							success: (items: MentionItem[]) => {
								if (items.length) {
									done(items);
								}
							}
						});
					}
				}, 0);
			},
			getSuggestionsHTML: (items: MentionItem[], type: MentionType) =>  {
				if (type === 'mention') {
					return '<div class="suggestion-item-list">' +
						items.map((i: MentionItem) =>
							'<div class="suggestion-item"><span class="glyphicon glyphicon-user"></span><div class="user">' +
								'<div class="suggestion-item-name">' + i.name + '</div>' +
								'<div class="suggestion-item-username">' + i.email + '</div>' +
							'</div></div>').join('') +
					'</div>';
				}
			}
		});

		//the mention plugin has a bug that throws an exception if you press enter when the
		//mention list is empty so we override the handleKeyDown method to catch the error
		const handleKeyDown = mentionPlugin.props.handleKeyDown;
		mentionPlugin.props.handleKeyDown = function (view: EditorView, e: KeyboardEvent) {
			try {
				var result = handleKeyDown(view, e);
				if (result && e.key === 'Enter') {
					//insert a space after selecting a user via the keyboard
					view.dispatch(view.state.tr.insertText(' '));
				}

				return result;
			}
			catch {
				if (e.key === 'Enter') {
					return true;
				}
			}

			return false;
		}

		plugins.unshift(mentionPlugin);

		const state = EditorState.create({schema, plugins});
		const editor = new EditorView(element.querySelector('.editor'), {
			state,
			dispatchTransaction(transaction) {
				const newState = editor.state.apply(transaction)
				editor.updateState(newState);
				viewModel.parse(editor.dom.innerHTML);
			}
		});

		editor.dom.innerHTML = viewModel.value();
	}
}